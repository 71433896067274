/* eslint-disable max-lines */
import {
	createContext,
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import aa from 'search-insights';
import { useRouter } from 'next/router';
import { useIsMutating } from '@tanstack/react-query';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { nanoid } from 'nanoid';
import { CartMutationLineItem, NormalizedCart, NormalizedCartLine, NormalizedVariant } from '@ts/index';
import { trackAddToCart, trackRemoveFromCart } from '@services/analytics/trackers';
import {
	useCart,
	useCartAdd,
	useCartAttributesUpdate,
	useCartDiscountUpdate,
	useCartRemove,
	useCartReplace,
	useCartUpdate,
	useCartUpdateBuyer,
	useCustomer,
} from '@services/shopify';
import { getShopifyIdFromGID } from '@utils/shopify';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import { createBaseFrameVariant, fetchBaseFrameVariant, VariantState } from '@services/shopify/hooks/useBaseFrameVariant';
import { normalizeProductType } from '@utils/normalizers';
import { ALGOLIA_SEARCH_INDEXES, findLensPackageInLensOptions, LOCALE_DICT, PRODUCT_TYPES } from '..';

type CartProviderProps = {
	children: JSX.Element;
};

type BaseUpsellActionArgs = {
	lineToUpdate: NormalizedCartLine;
	currentState: VariantState;
	existingLenses: BASE_FRAME_LENS_OPTIONS[];
	value: BASE_FRAME_LENS_OPTIONS;
};

type CartContextProps = {
	isCartMutating: boolean;
	searchQueryId?: string;
	isUploadingRx: boolean;
	microcartProduct: NormalizedCartLine;
	isMicrocartTest: boolean;
	lastActiveItem: MutableRefObject<HTMLButtonElement | null>;
	setIsUploadingRx: Dispatch<SetStateAction<boolean>>;
	setSearchQueryId: Dispatch<SetStateAction<string>>;
	handleCartAdd: (
		lineItems: CartMutationLineItem[],
		shouldOpenCart?: boolean,
		ref?: MutableRefObject<HTMLButtonElement | null>
	) => void;
	handleCartUpdate: (lineItems: CartMutationLineItem[]) => void;
	handleCartReplace: (lineItemIds: string[], lineItems: CartMutationLineItem[], fromBundle?: boolean) => void;
	handleCartRemove: (lineItemIds: string[], variant?: NormalizedVariant, path?: string) => void;
	handleCartDiscountUpdate: (cartId: string, discountCodes: string[]) => void;
	handleUpdateBuyer: (buyerIdentity: { customerAccessToken?: string; email?: string; countryCode?: string }) => void;
	handleBaseFrameUpsellAction: (args: BaseUpsellActionArgs) => void;
	setMicrocartProduct: Dispatch<SetStateAction<NormalizedCartLine>>;
	setLastActiveItem: Dispatch<SetStateAction<MutableRefObject<HTMLButtonElement | null>>>;
	getSubscriptionItem: (handle: string) => NormalizedCartLine;
	applySubscriptionDiscount: boolean;
};

const CartContext = createContext<CartContextProps>(null);

export function CartProvider({ children }: CartProviderProps) {
	const [searchQueryId, setSearchQueryId] = useState(null);
	const Customer = useCustomer();
	const router = useRouter();
	const { data: cart } = useCart();
	const { mutateAsync: cartAdd } = useCartAdd();
	const { mutateAsync: cartRemove } = useCartRemove();
	const { mutateAsync: cartReplace } = useCartReplace();
	const { mutateAsync: cartUpdate } = useCartUpdate();
	const { mutateAsync: cartUpdateBuyer } = useCartUpdateBuyer();
	const { mutateAsync: cartDiscountUpdate } = useCartDiscountUpdate();
	const { mutateAsync: cartAttributesUpdate } = useCartAttributesUpdate();
	const [lastActiveItem, setLastActiveItem] = useState<MutableRefObject<HTMLButtonElement | null>>(null);
	const isCartMutating = Boolean(useIsMutating({ mutationKey: ['cart'], exact: false }));
	const [isUploadingRx, setIsUploadingRx] = useState(false);
	const [microcartProduct, setMicrocartProduct] = useState(null);
	const isMicrocartTest = useFeatureIsOn('is-microcart-test');
	const countryCode = LOCALE_DICT[router.locale]?.countryCode;
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');

	const applySubscriptionDiscount = useMemo(() => {
		const subscriptionLine = cart?.lines?.filter(line => !!line.sellingPlanAllocation);
		return subscriptionLine?.length > 0 && isV2Subscription;
	}, [cart, isV2Subscription]);

	const handleCartAttributesEvent = event => {
		cartAttributesUpdate(event.detail);
	};

	useEffect(() => {
		document.addEventListener('geCartAttributes', handleCartAttributesEvent, false);
		return () => {
			document.removeEventListener('geCartAttributes', handleCartAttributesEvent, false);
		};
	}, []);

	const handleRouteChange = useCallback(() => {
		setMicrocartProduct(false);
	}, []);

	useEffect(() => {
		router.events.on('routeChangeStart', handleRouteChange);
		router.events.on('routeChangeError', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
			router.events.off('routeChangeError', handleRouteChange);
		};
	}, [router, handleRouteChange]);

	useEffect(() => {
		const cartCountryCode = cart?.buyerIdentity?.countryCode;
		if (!!cartCountryCode && cartCountryCode !== 'US') {
			const subscriptionLine = cart?.lines?.filter(line => !!line.sellingPlanAllocation?.sellingPlan?.id);
			subscriptionLine?.length > 0 && handleCartRemove(subscriptionLine.map(line => line.id));
		}
		if (!!cartCountryCode && cartCountryCode !== countryCode) {
			handleUpdateBuyer({ countryCode });
		}
	}, [cart]);

	useEffect(() => {
		window.geMerchantSession = {
			platformCartId: cart?.id,
			cartId: cart?.id,
			sessionId: Customer.data?.id || nanoid(),
		};
		const event = new CustomEvent('geMerchantSession', {
			bubbles: true,
			detail: window.geMerchantSession,
		});
		document.dispatchEvent(event);
	}, [cart?.id]);

	function handleCartAdd(lineItems: CartMutationLineItem[], shouldOpenCart = true, ref) {
		const cleanItems = lineItems.map(item => {
			const isCustomDiscount = item.customAttributes.find(
				att => att.key === '_custom_bundle_key' || att.key === '_custom_subscription_discount'
			);
			const existingTopFrame =
				normalizeProductType(item.variant.type) === PRODUCT_TYPES.TOP_FRAME &&
				!isCustomDiscount &&
				cart.lines.find(
					line =>
						line.variant.id === item.variant.id &&
						!(line.properties._custom_bundle_key || line.properties._custom_subscription_discount)
				);
			if (existingTopFrame) {
				const customAttributes = Object.entries(existingTopFrame.properties).map(([key, value]) => ({ key, value }));
				return {
					...item,
					customAttributes,
				};
			}
			return { ...item };
		});

		cartAdd({ lineItems: cleanItems });

		trackAddToCart({
			variants: lineItems.map(line => {
				if (searchQueryId) {
					aa('sendEvents', [
						{
							eventType: 'conversion',
							eventName: 'Product Added To Cart',
							index: ALGOLIA_SEARCH_INDEXES[countryCode].SHOPIFY_PRODUCTS,
							userToken: `${
								Customer.data
									? getShopifyIdFromGID(Customer.data.id)
									: `guest-user-${Math.floor(Math.random() * 1000000)}`
							}`,
							objectIDs: [
								`${getShopifyIdFromGID(line.variant.product.id)}`,
								`${getShopifyIdFromGID(line.variant.id)}`,
							],
							queryID: searchQueryId,
						},
					]);
				}
				return line.variant;
			}),
			path: router?.query?.collection
				? `top-frames/${router?.query?.collection}`
				: (lineItems[0].variant?.collection ?? ''),
		});

		if (isMicrocartTest) {
			setMicrocartProduct(lineItems[lineItems.length - 1]);
			return;
		}

		if (shouldOpenCart) {
			const minicart = document.getElementById('minicart');
			minicart.click();
			if (ref?.current) setLastActiveItem(ref);
		}
	}

	async function handleCartUpdate(lineItems: CartMutationLineItem[]) {
		const updatedCart = (await cartUpdate({ lineItems })) as NormalizedCart;

		const variantsToTrack = [];

		for (let i = 0; i < lineItems.length; i++) {
			const itemInPayload = lineItems[i];
			const lineWithMatchingId = updatedCart.lines.find(item => item.id === itemInPayload.id);
			lineWithMatchingId && variantsToTrack.push(lineWithMatchingId.variant);
		}

		if (!variantsToTrack) return;

		trackAddToCart({
			variants: variantsToTrack,
			path: router?.query?.collection ? `top-frames/${router?.query?.collection}` : (variantsToTrack[0]?.collection ?? ''),
		});
	}

	async function handleCartReplace(lineItemIds: string[], lineItems: CartMutationLineItem[], fromBundle = false) {
		const cleanItems = lineItems.map(item => {
			const existingTopFrame = fromBundle
				? normalizeProductType(item.variant.type) === PRODUCT_TYPES.TOP_FRAME &&
					!item.sellingPlanId &&
					cart.lines.find(
						line =>
							line.variant.id == item.variant.id &&
							line.properties?._custom_bundle_key ===
								item.customAttributes.find(attr => attr.key === '_custom_bundle_key')?.value
					)
				: normalizeProductType(item.variant.type) === PRODUCT_TYPES.TOP_FRAME &&
					!item.sellingPlanId &&
					cart.lines.find(line => line.variant.id == item.variant.id);
			if (existingTopFrame && !lineItemIds.includes(existingTopFrame?.id)) {
				lineItemIds.push(existingTopFrame?.id);
				return {
					...item,
					quantity: existingTopFrame?.quantity + item.quantity,
				};
			}
			return { ...item, quantity: existingTopFrame?.quantity ?? item.quantity };
		});

		const updatedCart = (await cartReplace({ lineItemIds, lineItems: cleanItems })) as NormalizedCart;

		const variantsToTrack = [];

		for (let i = 0; i < lineItems.length; i++) {
			const itemInPayload = lineItems[i];
			const lineWithMatchingId = updatedCart.lines.find(item => item.variant.id === itemInPayload.variant.id);
			variantsToTrack.push(lineWithMatchingId.variant);
		}

		trackAddToCart({
			variants: variantsToTrack,
			path: router?.query?.collection ? `top-frames/${router?.query?.collection}` : (variantsToTrack[0]?.collection ?? ''),
		});
	}

	async function handleBaseFrameUpsellAction({ lineToUpdate, currentState, existingLenses, value }: BaseUpsellActionArgs) {
		// determine what the new lenses should be
		let updatedLenses = existingLenses;
		if (existingLenses.includes(value)) {
			updatedLenses = existingLenses.filter(lens => lens !== value);
		} else {
			updatedLenses.push(value);
		}

		const lensPack = findLensPackageInLensOptions(updatedLenses);

		// get the new variant id
		try {
			const { variantBySelectedOptions } = await fetchBaseFrameVariant({
				...currentState,
				Lens: createBaseFrameVariant(updatedLenses),
				country: countryCode,
			});
			const updatedId = variantBySelectedOptions.id;
			return handleCartUpdate([
				{
					id: lineToUpdate.id,
					variant: {
						...lineToUpdate.variant,
						id: updatedId,
					},
					quantity: lineToUpdate.quantity,
					customAttributes: Object.entries(lineToUpdate.properties).map(([key, value]) => ({ key, value })),
				},
			]);
		} catch (error) {
			console.error(`Error fetching updated VID after Lens Upsell Action: ${error}`);
		}
	}

	function handleCartRemove(lineItemIds: string[], variant = null, path = null) {
		cartRemove({ lineItemIds, isOptimistic: false });

		if (!!variant && !!path) {
			trackRemoveFromCart({
				variant: variant,
				path: path,
			});
		}
	}

	function handleCartDiscountUpdate(cartId: string, discountCodes: string[]) {
		cartDiscountUpdate({ cartId, discountCodes });
	}

	function handleUpdateBuyer(buyerIdentity: { customerAccessToken?: string; email?: string; countryCode?: string }) {
		cartUpdateBuyer(buyerIdentity);
	}

	function getSubscriptionItem(handle: string) {
		return cart?.lines.find(line => line.variant.option.includes(handle) && line.sellingPlanAllocation?.sellingPlan.id);
	}

	return (
		<CartContext.Provider
			value={{
				isCartMutating,
				searchQueryId,
				isUploadingRx,
				microcartProduct,
				isMicrocartTest,
				lastActiveItem,
				setIsUploadingRx,
				setSearchQueryId,
				handleCartAdd,
				handleCartUpdate,
				handleCartReplace,
				handleCartRemove,
				handleCartDiscountUpdate,
				handleUpdateBuyer,
				handleBaseFrameUpsellAction,
				setMicrocartProduct,
				setLastActiveItem,
				getSubscriptionItem,
				applySubscriptionDiscount,
			}}
		>
			{children}
		</CartContext.Provider>
	);
}

export function useCartContext() {
	return useContext(CartContext);
}
